import React, { useState } from "react";
import { Link } from "gatsby";
import "twin.macro";
import tw, { styled } from "twin.macro";
import { StaticImage } from "gatsby-plugin-image";
// import NavLink from "../utils/NavLink";

export default function PrimaryNavbarX() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const active = { color: "#e30613", fontSIze: "1.125rem" };
  let MobileNavbar = styled.div``;
  if (navbarOpen) {
    MobileNavbar = styled.div`
      ${tw`absolute inset-x-0 top-0 z-10 block p-2 transition origin-top-right transform md:hidden`}
    `;
  } else {
    MobileNavbar = styled.div`
      ${tw`absolute inset-x-0 top-0 z-10 hidden p-2 transition origin-top-right transform md:hidden`}
    `;
  }

  let SubMenu = styled.div``;
  if (openSubMenu) {
    SubMenu = styled.div`
      ${tw`inset-x-0 z-10 block p-2 -my-6 transition origin-top-right transform `}
    `;
  } else {
    SubMenu = styled.div`
      ${tw`inset-x-0 z-10 hidden p-2 -my-6 transition origin-top-right transform `}
    `;
  }

  return (
    <div tw="relative bg-white px-10">
      <div tw="max-w-7xl mx-auto md:px-0 sm:px-6 md:pl-10">
        <div tw="flex justify-between items-center  py-6 md:justify-between md:space-x-10">
          <h1 tw="flex justify-start lg:w-0">
            <Link to="/">
              <span tw="sr-only">Real design team</span>
              <StaticImage
                src="../../images/RD_Logo_Rot_Retina.png"
                tw="h-8 w-12 md:w-24 md:h-16"
                alt="REALDESIGN|TEAM Logo"
              />
            </Link>
          </h1>
          <div tw="-mr-2 -my-2 md:hidden">
            {navbarOpen ? (
              <button
                role="button"
                aria-label="Open Menu"
                type="button"
                tw="bg-white rounded-md p-2 inline-flex items-center justify-center text-black hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-expanded="false"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <span tw="sr-only">Open menu</span>
                {/* <!-- Heroicon name: outline/menu --> */}
                <svg
                  tw="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            ) : (
              <button
                role="button"
                aria-label="Close Menu"
                type="button"
                tw="inline-flex items-center justify-center p-2 rounded-md text-black hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <span tw="sr-only">Close menu</span>
                <svg tw="h-6 w-6" fill="#ff5757" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            )}
          </div>

          <div tw="hidden md:flex items-center justify-end md:flex-1 lg:w-0 font-semibold ">
            <Link
              to="/"
              tw="text-lg font-semibold text-black hover:text-primary block  mr-8"
              activeStyle={active}
            >
              REALDESIGN | TEAM
            </Link>
            {/* <Link
              to="/"
              tw="text-base font-medium text-gray-500 hover:text-primary"
              activeStyle={active}
            >
              REALDESIGN | TEAM
            </Link> */}
            <div className="group" tw="relative mr-10">
              <p tw="hover:text-primary text-black hover:cursor-pointer">
                <Link
                  to="/ourservices"
                  partiallyActive={true}
                  activeStyle={active}
                  className=""
                  tw="flex flex-row items-center w-full px-4 py-4 mt-2 text-base font-medium  text-left  bg-transparent rounded-lg md:w-auto md:inline md:mt-0  focus:outline-none pointer-events-none "
                >
                  <span tw="text-lg font-semibold  hover:text-primary">
                    Unsere Dienstleistungen
                  </span>
                </Link>
              </p>
              <div
                className="group-hover:block"
                tw="absolute z-10 hidden  group-hover:block"
              >
                <div tw="px-2 pt-2 pb-4   ">
                  <div tw="grid grid-cols-1 gap-4 ">
                    {/* <div tw="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"> */}
                    <div tw="rounded-lg  overflow-hidden w-64">
                      <div tw="relative grid gap-6 bg-white pl-5 pr-2 py-6 sm:gap-8 sm:p-8 font-semibold text-base">
                        <Link
                          to="/ourservices/onlinemarketing"
                          tw="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 hover:text-primary font-semibold"
                          activeStyle={active}
                        >
                          {/* <!-- Heroicon name: outline/chart-bar --> */}
                          Online Marketing
                        </Link>
                        <Link
                          to="/ourservices/offlinemarketing"
                          tw="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 hover:text-primary font-semibold"
                          activeStyle={active}
                        >
                          {/* <!-- Heroicon name: outline/chart-bar --> */}
                          Offline Marketing
                        </Link>
                        <Link
                          to="/ourservices/cooking"
                          tw="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 hover:text-primary font-semibold"
                          activeStyle={active}
                        >
                          {/* <!-- Heroicon name: outline/chart-bar --> */}
                          Kochkurse
                        </Link>
                        <Link
                          to="/ourservices/podcast"
                          tw="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 hover:text-primary font-semibold"
                          activeStyle={active}
                        >
                          {/* <!-- Heroicon name: outline/chart-bar --> */}
                          Podcast
                        </Link>
                        <Link
                          to="/ourservices/baking"
                          tw="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 hover:text-primary font-semibold"
                          activeStyle={active}
                        >
                          {/* <!-- Heroicon name: outline/chart-bar --> */}
                          Backen
                        </Link>

                        {/* <div tw="">
                    <p tw="text-base font-medium text-gray-900">
                    </p>
                  </div> */}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
            <Link
              to="/#references"
              tw="text-lg font-semibold text-black hover:text-primary mr-12"
              activeStyle={active}
            >
              Unsere Referenzen
            </Link>

            <Link
              to="/contact"
              tw="text-lg font-semibold text-black  hover:text-primary"
              activeStyle={active}
            >
              Kontakt
            </Link>
          </div>
        </div>
      </div>

      {/* <!--
    Mobile menu, show/hide based on mobile menu state.

    Entering: "duration-200 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
  --> */}
      <MobileNavbar>
        <div tw="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div tw="pt-5 pb-6 px-5">
            <div tw="flex items-center justify-between">
              <div>
                <Link to="/">
                  <span tw="sr-only">Real design team</span>

                  <StaticImage
                    src="../../images/RD_Logo_Rot_Retina.png"
                    tw="h-8 w-16 sm:h-10"
                    alt="REALDESIGN|TEAM Logo"
                  />
                </Link>
              </div>
              <div tw="-mr-2">
                <button
                  type="button"
                  aria-label="Close Menu"
                  tw="bg-white rounded-md p-2 inline-flex items-center justify-center text-black hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  <span tw="sr-only">Close menu</span>
                  {/* <!-- Heroicon name: outline/x --> */}
                  <svg
                    tw="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div tw="mt-6">
              <nav tw="grid gap-y-8 font-bold">
                <Link
                  to="/"
                  tw="-m-3 p-3 flex items-center rounded-md text-black hover:text-primary "
                  activeStyle={active}
                >
                  {/* <!-- Heroicon name: outline/chart-bar --> */}

                  <span tw="ml-3 text-base font-semibold ">
                    REALDESIGN | TEAM
                  </span>
                </Link>

                <button
                  aria-label="Open Submenu"
                  href="#"
                  tw="-m-3 p-3 flex items-center rounded-md text-gray-900 hover:bg-gray-50 hover:text-primary"
                  onClick={() => setOpenSubMenu(!openSubMenu)}
                >
                  {/* <!-- Heroicon name: outline/cursor-click --> */}
                  <Link
                    to="/ourservices"
                    partiallyActive={true}
                    activeStyle={active}
                    tw="pointer-events-none"
                  >
                    <span tw="ml-3 text-base font-semibold text-black  hover:text-primary">
                      Unsere Dienstleistungen
                    </span>
                  </Link>
                </button>
                <SubMenu tw="py-1 font-semibold" role="none">
                  <Link
                    to="/ourservices/onlinemarketing"
                    tw="block px-4 py-2 text-sm text-black hover:text-primary"
                    role="menuitem"
                    activeStyle={active}
                  >
                    Online Marketing
                  </Link>
                  <Link
                    to="/ourservices/offlinemarketing"
                    tw="block px-4 py-2 text-sm text-black hover:text-primary"
                    role="menuitem"
                    activeStyle={active}
                  >
                    Offline Marketing
                  </Link>
                  <Link
                    to="/ourservices/cooking"
                    tw="block px-4 py-2 text-sm text-black hover:text-primary"
                    role="menuitem"
                    activeStyle={active}
                  >
                    Kochkurse
                  </Link>
                  <Link
                    to="/ourservices/podcast"
                    tw="block px-4 py-2 text-sm text-black hover:text-primary"
                    role="menuitem"
                    activeStyle={active}
                  >
                    Podcast
                  </Link>
                  <Link
                    to="/ourservices/baking"
                    tw="block px-4 py-2 text-sm text-black hover:text-primary"
                    role="menuitem"
                    activeStyle={active}
                  >
                    Backen
                  </Link>
                </SubMenu>

                <Link
                  to="/#references"
                  tw="-m-3 p-3 flex items-center rounded-md text-black hover:text-primary"
                  activeStyle={active}
                >
                  {/* <!-- Heroicon name: outline/shield-check --> */}
                  <span tw="ml-3 text-base font-semibold ">
                    Unsere Referenzen
                  </span>
                </Link>

                <Link
                  to="/contact"
                  tw="-m-3 p-3 flex items-center rounded-md text-black hover:text-primary"
                  activeStyle={active}
                >
                  <span tw="ml-3 text-base font-semibold ">Kontakt</span>
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </MobileNavbar>
    </div>
  );
}
